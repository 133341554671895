import React, {useEffect, useState} from "react";
import {View} from "../Reusable Component/View";
import dashboard from "../assets/dashboard.jpg";
import Navbar from "../Libraries/Navbar";
import {useNavigate} from "react-router-dom";
import withLogout from "../AutoLogOut/withLogout";
import { LOCAL_ROUTES } from "../Utils/enums";

const Dashboard: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const hashParams = window.location.hash.substring(1).split("&");
  const accessTokenParam = hashParams.find(param =>
    param.startsWith("access_token="),
  );
  const accessToken = accessTokenParam?.split("=")[1];
  sessionStorage.setItem("Access", accessToken!);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOnClick = () => {
    navigate(`${LOCAL_ROUTES.NIGHTLY_TEST_VIEW}#`);
  };
  return (
    <div>
      <Navbar isOpen={isOpen} onToggle={handleToggle} />
      <div
        className="h-screen w-screen flex items-center justify-center bg-gray-100"
        style={{
          backgroundImage: `url(${dashboard})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}>
        <div className="w-full bg-transparent p-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <View
              title="Pipeline View"
              description="Aenean consectetur odio in condimentum tristique. Nam hendrerit urna ex, non pretium erat pellentesque eget. Sed ut risus nec augue sagittis convallis."
              handleOnClick={handleOnClick}
            />
            <View
              title="Release View"
              description="Aenean consectetur odio in condimentum tristique. Nam hendrerit urna ex, non pretium erat pellentesque eget. Sed ut risus nec augue sagittis convallis."
              handleOnClick={function (): void {
                throw new Error("Function not implemented.");
              }}
            />
            <View
              title="Timeline View"
              description="Aenean consectetur odio in condimentum tristique. Nam hendrerit urna ex, non pretium erat pellentesque eget. Sed ut risus nec augue sagittis convallis."
              handleOnClick={function (): void {
                throw new Error("Function not implemented.");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLogout(Dashboard);
