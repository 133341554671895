import React, {useState, useEffect, useCallback} from "react";
import axios, {AxiosError} from "axios";
import WiFi from "../../assets/wi-fi.png";
import Flash from "../../assets/thunder-bolt.png";
import Ethernet from "../../assets/ethernet.png";
import Orphaned from "../../assets/computer_15689006.png";
import LEDs from "../../assets/led-light.png";
import Reboot from "../../assets/rewind.png";
import {useNavigate} from "react-router-dom";
import ChildTable from "../../Reusable Component/ChildTable";

export const HardwareFolderBar: React.FC = () => {
  const [sidebarItems, setSidebarItems] = useState([]);
  const [apiData, setApiData] = useState({});
  const [folderTestCount, setFolderTestCount] = useState<any>([]);
  const navigate = useNavigate();
  const [tokenFolderHardware, setTokenFolderHardware] = useState("");
  const [loading, setLoading] = useState(false);
  const date = sessionStorage.getItem("Pipeline Date");
  const apiUrl = process.env.REACT_APP_API_URL;

  const iconMapping: any = {
    "Wi-Fi": WiFi,
    Ethernet: Ethernet,
    Flash: Flash,
    LEDs: LEDs,
    Reboot: Reboot,
    default: Orphaned,
  };

  useEffect(() => {
    const tokenHardware = sessionStorage.getItem("Access");
    if (tokenHardware) {
      setTokenFolderHardware(tokenHardware);
    }
  });

  const fetchFoldersDataHardware = useCallback(async () => {
    try {
      setLoading(true);
      const headers = {
        Authorization: tokenFolderHardware,
        Accept: "*/*",
      };

      try {
        const folderResponse = await axios.get(
          `${apiUrl}/tests/folders/9171?type=NIGHTLY&nightlyDate=${date}`,
          {headers},
        );
        const items = folderResponse.data.childFolders.map((item: any) => ({
          label: item.name.replace(/[0-9]\) | \(x\)|x\)/g, ""),
          icon:
            iconMapping[item.name.replace(/[0-9]\) | \(x\)|x\)/g, "")] ||
            iconMapping.default,
        }));
        setSidebarItems(items);
        setApiData(folderResponse.data);
        if (folderResponse.data.childFolders) {
          const folderTestCount = Object.keys(
            folderResponse.data.childFolders,
          ).map(key => ({
            label: folderResponse.data.childFolders[key].name,
            total: folderResponse.data.childFolders[key].total,
            pass: folderResponse.data.childFolders[key].pass,
            fail: folderResponse.data.childFolders[key].fail,
            skip: folderResponse.data.childFolders[key].skip,
            missing: folderResponse.data.childFolders[key].missing
          }));
          setFolderTestCount(folderTestCount);
        } else {
          setFolderTestCount([]);
        }
      } catch (error: any) {
        if (
          error instanceof AxiosError &&
          error.response &&
          error.response.status === 504
        ) {
          setFolderTestCount([]);
        } else {
          console.error(error);
        }
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, [tokenFolderHardware]);

  useEffect(() => {
    if (tokenFolderHardware) {
      void fetchFoldersDataHardware();
    }
  }, [fetchFoldersDataHardware, tokenFolderHardware]);

  return (
    <div>
      <ChildTable
        loading={loading}
        data={sidebarItems}
        onClick={function (
          event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        ): void {
          throw new Error("Function not implemented.");
        }}
        folderTestCount={folderTestCount}
      />
    </div>
  );
};
