import React, {useEffect} from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Dashboard from "../Pages/Dashboard";
import PipelineView from "../Pages/PipeLineView";
import TestSetUp from "../Pages//One Level Children/TestSetUp";
import Software from "../Pages//One Level Children/Software";
import Hardware from "../Pages/One Level Children/Hardware";
import DataModel from "../Pages//One Level Children/DataModel";
import Orphaned from "../Pages//One Level Children/Orphaned";
import LTEModem from "../Pages/One Level Children/LTEModem";
import NightlyTest from "../Pages/NightlyTest";
import BtServices from "../Pages//Second Level Children/BtServices";

interface IProtectedRoute {
  isAuthenticated: boolean;
}

const ProtectedRoutes: React.FC<IProtectedRoute> = ({isAuthenticated}) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Redirect to login page if user is not authenticated
    if (
      !isAuthenticated &&
      (location.pathname === "/dashboard" ||
        location.pathname === "/pipelineview" ||
        location.pathname === "/pipelineview/test-setup" ||
        location.pathname === "/pipelineview/software" ||
        location.pathname === "/pipelineview/hardware" ||
        location.pathname === "/pipelineview/datamodel" ||
        location.pathname === "/pipelineview/orphaned" ||
        location.pathname === "/pipelineview/lte-modem" ||
        location.pathname === "/nightlytestview")
    ) {
      navigate("/");
    }
  }, [isAuthenticated, location.pathname, navigate]);

  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route
        path="/nightlytestview"
        element={<NightlyTest isAuthenticated={isAuthenticated} />}
      />
      <Route
        path="/pipelineview"
        element={<PipelineView isAuthenticated={isAuthenticated} />}
      />
      <Route
        path="/pipelineview/test-setup"
        element={<TestSetUp isAuthenticated={isAuthenticated} />}
      />
      <Route
        path="/pipelineview/software"
        element={<Software isAuthenticated={isAuthenticated} />}
      />
      <Route
        path="/pipelineview/hardware"
        element={<Hardware isAuthenticated={isAuthenticated} />}
      />
      <Route
        path="/pipelineview/datamodel"
        element={<DataModel isAuthenticated={isAuthenticated} />}
      />
      <Route
        path="/pipelineview/orphaned"
        element={<Orphaned isAuthenticated={isAuthenticated} />}
      />
      <Route
        path="/pipelineview/lte-modem"
        element={<LTEModem isAuthenticated={isAuthenticated} />}
      />
      <Route
        path="/pipelineview/software/btservices"
        element={<BtServices isAuthenticated={isAuthenticated} />}
      />
    </Routes>
  );
};

export default ProtectedRoutes;
