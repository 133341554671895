import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {DataTable} from "../../Reusable Component/DataTable";
import {renderPagination} from "../../Reusable Component/RenderPagination";

const SoftwareTable: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(3);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [summaryData, setSummaryData] = useState<any>({
    total: 0,
    pass: 0,
    fail: 0,
    skip: 0,
    missing: 0,
  });
  const [tokenSoftware, setTokenSoftware] = useState("");
  const [loading, setLoading] = useState(false);
  const date = sessionStorage.getItem("Pipeline Date");
  const apiUrl = process.env.REACT_APP_API_URL;
  const jiraUrl = process.env.REACT_APP_JIRA_URL;

  useEffect(() => {
    const tokenFromSessionSoftware = sessionStorage.getItem("Access");
    if (tokenFromSessionSoftware) {
      setTokenSoftware(tokenFromSessionSoftware);
    }
  }, []);

  const fetchAllDataSoftware = useCallback(async () => {
    try {
      setLoading(true);
      const headers = {
        Authorization: tokenSoftware,
        Accept: "*/*",
      };

      // Fetch summary data
      const summaryResponse = await axios.get(
        `${apiUrl}/tests/folders/9164?type=NIGHTLY&nightlyDate=${date}`,
        {headers},
      );
      const apiData = summaryResponse.data.childIssues;
      const tableData = apiData.map((item: any, index: number) => ({
        key: item.id,
        summary: item.name,
        id: index,
      }));
      setFilteredData(tableData);
      const childIssues = summaryResponse.data.childIssues;
      const summaryData = childIssues.reduce(
        (acc: {[x: string]: any}, issue: {id: string | number}) => {
          acc[issue.id] = issue;
          return acc;
        },
        {},
      );
      setSummaryData(summaryData);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, [tokenSoftware]);

  useEffect(() => {
    if (tokenSoftware) {
      fetchAllDataSoftware();
    }
  }, [tokenSoftware]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleLinkClick = (key: any) => {
    const jiraLink = `${jiraUrl}?detail=TCS&issueKey=${key}`;
    window.location.href = jiraLink;
  };

  // Paginate the filtered test results
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData
    .slice(indexOfFirstItem, indexOfLastItem)
    .map((item: any) => ({
      ...item,
      total: summaryData[item.id] ? summaryData[item.id].total : 0,
      pass: summaryData[item.id] ? summaryData[item.id].pass : 0,
      fail: summaryData[item.id] ? summaryData[item.id].fail : 0,
      skip: summaryData[item.id] ? summaryData[item.id].skip : 0,
      missing: summaryData[item.id] ? summaryData[item.id].missing : 0,
    }));
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <div className="rounded-lg border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1 w-[93.2vw]">
      <h4 className="mb-6 text-xl font-semibold text-black font-serif">
        Issues For: Software
      </h4>
      <div className="mb-4">
        <p>Version: r2.50</p>
        <p>Hub Type: - No Hub</p>
      </div>
      <br />
      <DataTable
        loading={loading}
        filteredData={filteredData}
        currentItems={currentItems}
        summaryData={summaryData}
        handleLinkClick={handleLinkClick}
        renderPagination={renderPagination({
          currentPage,
          totalPages,
          handlePageChange,
          maxButtonsToShow: 5,
        })}
      />
    </div>
  );
};

export default SoftwareTable;
