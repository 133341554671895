import React, {useState, useEffect, useCallback} from "react";
import axios, {AxiosError} from "axios";
import BTService from "../../assets/bhutan.png";
import RGCF from "../../assets/apartment.png";
import Software from "../../assets/coding_2010990.png";
import DM from "../../assets/data-management.png";
import TMP from "../../assets/file.png";
import Applications from "../../assets/test.png";
import TestSetUp from "../../assets/web-settings-confirmed_74892.png";
import Home from "../../assets/find.png";
import BTT from "../../assets/verified-user_9957231.png";
import Container from "../../assets/container.png";
import Orphaned from "../../assets/computer_15689006.png";
import {useNavigate} from "react-router-dom";
import ChildTable from "../../Reusable Component/ChildTable";

export const SoftwareFolderBar: React.FC = () => {
  const [sidebarItems, setSidebarItems] = useState<any>([]);
  const [apiData, setApiData] = useState({});
  const [folderTestCount, setFolderTestCount] = useState<any>([]);
  const navigate = useNavigate();
  const [tokenFolderSoftware, setTokenFolderSoftware] = useState("");
  const [loading, setLoading] = useState(false);
  const date = sessionStorage.getItem("Pipeline Date");
  const apiUrl = process.env.REACT_APP_API_URL;

  const iconMapping: any = {
    "BT Services": BTService,
    "Residential Gateway Core Functions": RGCF,
    "BT Smart Framework": BTService,
    "System Software": Software,
    Deprecated_tests: TestSetUp,
    "Device Management": DM,
    Temp: TMP,
    Applications: Applications,
    Homeflow: Home,
    HomeView: Home,
    "Test Cases Belfast Team": BTT,
    Containerisation: Container,
    default: Orphaned,
  };

  useEffect(() => {
    const tokenSoftware = sessionStorage.getItem("Access");
    if (tokenSoftware) {
      setTokenFolderSoftware(tokenSoftware);
    }
  });

  const fetchFoldersDataSoftware = useCallback(async () => {
    try {
      setLoading(true);
      const headers = {
        Authorization: tokenFolderSoftware,
        Accept: "*/*",
      };

      try {
        const folderResponse = await axios.get(
          `${apiUrl}/tests/folders/9164?type=NIGHTLY&nightlyDate=${date}`,
          {headers},
        );
        const items = folderResponse.data.childFolders.map((item: any) => ({
          label: item.name.replace(/[0-9]\) | \(x\)|x\)/g, ""),
          icon:
            iconMapping[item.name.replace(/[0-9]\) | \(x\)|x\)/g, "")] ||
            iconMapping.default,
        }));
        setSidebarItems(items);
        setApiData(folderResponse.data);
        if (folderResponse.data.childFolders) {
          const folderTestCount = Object.keys(
            folderResponse.data.childFolders,
          ).map(key => ({
            label: folderResponse.data.childFolders[key].name,
            total: folderResponse.data.childFolders[key].total,
            pass: folderResponse.data.childFolders[key].pass,
            fail: folderResponse.data.childFolders[key].fail,
            skip: folderResponse.data.childFolders[key].skip,
            missing: folderResponse.data.childFolders[key].missing,
          }));
          setFolderTestCount(folderTestCount);
        } else {
          setFolderTestCount([]);
        }
      } catch (error: any) {
        if (
          error instanceof AxiosError &&
          error.response &&
          error.response.status === 504
        ) {
          setFolderTestCount([]);
        } else {
          console.error(error);
        }
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, [tokenFolderSoftware]);

  useEffect(() => {
    if (tokenFolderSoftware) {
      void fetchFoldersDataSoftware();
    }
  }, [fetchFoldersDataSoftware, tokenFolderSoftware]);

  return (
    <div>
      <ChildTable
        loading={loading}
        data={sidebarItems}
        onClick={(item: any) => {
          if (item.label === "BT Services") {
            navigate("/pipelineview/software/btservices/");
          } else {
            alert("Function not implemented");
          }
        }}
        folderTestCount={folderTestCount}
      />
    </div>
  );
};
