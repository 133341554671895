export const LOCAL_ROUTES = {
  DASHBOARD_VIEW: "/dashboard/",
  NIGHTLY_TEST_VIEW: "/nightlytestview/",
  PIPELINE_VIEW: "/pipelineview/",
  SOFTWARE_VIEW: "/pipelineview/software/",
  BT_SERVICES_VIEW: "/pipelineview/software/btservices/",
  DATA_MODEL_VIEW: "/pipelineview/datamodel/",
  HARDWARE_VIEW: "/pipelineview/hardware/",
  LTE_MODEM_VIEW: "/pipelineview/lte-modem/",
  ORPHANED_VIEW: "/pipelineview/orphaned/",
  TEST_SETUP_VIEW: "/pipelineview/test-setup/",
};
