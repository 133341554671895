import React from "react";

interface DataTableProps {
  loading: boolean;
  filteredData: any[];
  currentItems: any[];
  summaryData: {[key: string]: any};
  handleLinkClick: (key: string) => void;
  renderPagination: any;
}

export const DataTable: React.FC<DataTableProps> = ({
  loading,
  filteredData,
  currentItems,
  summaryData,
  handleLinkClick,
  renderPagination,
}) => {
  return (
    <div>
      {loading ? (
        <div className="px-6 py-24 text-center">
          <div
            className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status">
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      ) : filteredData.length > 0 ? (
        <>
          <div className="flex flex-col border">
            <div className="grid grid-cols-3 rounded-sm bg-gray-100 dark:bg-meta-4 sm:grid-cols-7 overflow-x-auto">
              <div className="p-2.5 xl:p-5">
                <h5 className="text-sm font-medium uppercase xsm:text-base">
                  TCS ID
                </h5>
              </div>
              <div className="p-2.5 text-center xl:p-5">
                <h5 className="text-sm font-medium uppercase xsm:text-base">
                  Summary
                </h5>
              </div>
              <div className="hidden p-2.5 text-center sm:block xl:p-5">
                <h5 className="text-sm font-medium uppercase xsm:text-base">
                  Total Test Cases
                </h5>
              </div>
              <div className="hidden p-2.5 text-center sm:block xl:p-5">
                <h5 className="text-sm font-medium uppercase xsm:text-base">
                  Tests Passed
                </h5>
              </div>
              <div className="hidden p-2.5 text-center sm:block xl:p-5">
                <h5 className="text-sm font-medium uppercase xsm:text-base">
                  Tests Failed
                </h5>
              </div>
              <div className="hidden p-2.5 text-center sm:block xl:p-5">
                <h5 className="text-sm font-medium uppercase xsm:text-base">
                  Tests Skipped
                </h5>
              </div>
              <div className="hidden p-2.5 text-center sm:block xl:p-5">
                <h5 className="text-sm font-medium uppercase xsm:text-base">
                  Tests Missing
                </h5>
              </div>
            </div>

            {currentItems.map((brand: any, key) => (
              <div
                className={`grid grid-cols-3 sm:grid-cols-7 ${
                  key === currentItems.length - 1
                    ? ""
                    : "border border-stroke dark:border-strokedark"
                }`}
                key={key}>
                <div className="flex items-center gap-3 p-2.5 xl:p-5">
                  <a
                    href="#"
                    onClick={() => handleLinkClick(brand.key)}
                    className="text-indigo-600 sm:block">
                    {brand.key}
                  </a>
                </div>

                <div className="flex items-center justify-center p-2.5 xl:p-5">
                  <p className="truncate hover:overflow-visible hover:whitespace-normal hover:h-auto">
                    {brand.summary}
                  </p>
                </div>

                <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
                  <p className="text-black">
                    {summaryData[brand.key] ? summaryData[brand.key].total : 0}
                  </p>
                </div>

                <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
                  <p className="text-black">
                    {summaryData[brand.key] ? summaryData[brand.key].pass : 0}
                  </p>
                </div>

                <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
                  <p className="text-meta-5">
                    {summaryData[brand.key] ? summaryData[brand.key].fail : 0}
                  </p>
                </div>

                <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
                  <p className="text-meta-5">
                    {summaryData[brand.key] ? summaryData[brand.key].skip : 0}
                  </p>
                </div>

                <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
                  <p className="text-meta-5">
                    {summaryData[brand.key] ? summaryData[brand.key].missing : 0}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4 mb-2">{renderPagination}</div>
        </>
      ) : (
        <div className="px-6 py-24 text-center" style={{fontWeight: "bold"}}>
          <h1 className="text-bold font-serif">No issues found ☹️ !!</h1>
        </div>
      )}
    </div>
  );
};
