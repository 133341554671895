import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {useNavigateToDashboard} from "../CustomHooks/NavigationHooks";
import LoadingIndicator from "../Reusable Component/LoadingIndicator";
import Navbar from "../Libraries/Navbar";
import axios from "axios";
import Graph from "../Libraries/GraphicalComponent/Graph";
import { LOCAL_ROUTES } from "../Utils/enums";

interface NightlyTestProps {
  isAuthenticated: boolean;
}

const NightlyTest: React.FC<NightlyTestProps> = ({isAuthenticated}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [cardData, setCardData] = useState<any>([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const location = useLocation();

  const {handleNavigateToDashboard} = useNavigateToDashboard(
    isAuthenticated,
    location.pathname,
  );

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const token = sessionStorage.getItem("Access");

  const dashboardUrl = `${LOCAL_ROUTES.DASHBOARD_VIEW}#access_token=${token}`;

  useEffect(() => {
    const headers = {
      Authorization: token,
      Accept: "*/*",
    };
    // Fetch the API data using Axios
    axios
      .get(`${apiUrl}/tests/report?type=NIGHTLY`, {headers})
      .then(response => {
        // Map the API data to the card data
        const cardData = response.data.data.map(
          (item: {
            reportDate: string | number | Date;
            pass: any;
            fail: any;
            total: any;
          }) => {
            // Return a new card data object
            return {
              date: item.reportDate,
              total: item.total,
              pass: item.pass,
              fail: item.fail,
            };
          },
        );

        // Set the card data state
        setCardData(cardData);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 15000);
    return () => clearTimeout(timer);
  }, []);

  const handleCardClick = (date: string) => {
    sessionStorage.setItem("Pipeline Date", date!);
    navigate(`${LOCAL_ROUTES.PIPELINE_VIEW}#`);
  };

  return (
    <div>
      <Navbar isOpen={isOpen} onToggle={handleToggle} />
      <LoadingIndicator isLoading={isLoading} />
      <div
        className={`flex h-screen w-[120vw] p-8 ${isLoading ? "opacity-20 cursor-not-allowed" : ""}`}>
        <div className="w-4/5 bg-white -p-[0.5rem]">
          <div className="flex items-center text-gray-500 text-sm">
            <a
              href={dashboardUrl}
              className="mr-2 hover:text-gray-700"
              onClick={handleNavigateToDashboard}>
              Home
            </a>
            <span className="mr-2">{">"}</span>
            <a
              href="#"
              className={`mr-2 hover:text-gray-700 ${
                location.pathname === LOCAL_ROUTES.NIGHTLY_TEST_VIEW
                  ? "font-bold text-black"
                  : "text-gray-700"
              }`}>
              Nightly Test
            </a>
          </div>
          <br />
          <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold font-serif mb-4">Nightly Test</h1>
            <br />
            <div className="grid grid-cols-4 gap-5">
              {cardData.map((card: any, index: number) => (
                <div
                  key={card.date}
                  className={`border border-gray-300 rounded shadow p-4 cursor-pointer ${selectedDate === card.date ? "bg-blue-50" : ""}`}
                  style={{gridColumn: `span ${index === 6 ? 2 : 1}`}}
                  onClick={() => handleCardClick(card.date)}>
                  <h2 className="text-lg font-medium mb-2">
                    Report Date: {card.date}
                  </h2>
                  <p>Total Count: {card.total}</p>
                  <p>Pass Count: {card.pass}</p>
                  <p>Fail Count: {card.fail}</p>
                </div>
              ))}
            </div>
            <Graph cardData={cardData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NightlyTest;
