import React, {useState} from "react";
import "./App.css";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import PipelineView from "./Pages/PipeLineView";
import Login from "./Pages/Login";
import ProtectedRoutes from "./CustomHooks/ProtectedRoute";
import TestSetUp from "./Pages/One Level Children/TestSetUp";
import Software from "./Pages/One Level Children//Software";
import NotFoundPage from "./Pages/NotFoundPage";
import Hardware from "./Pages/One Level Children/Hardware";
import DataModel from "./Pages/One Level Children//DataModel";
import Orphaned from "./Pages/One Level Children//Orphaned";
import LTEModem from "./Pages/One Level Children/LTEModem";
import NightlyTest from "./Pages/NightlyTest";
import BtServices from "./Pages/Second Level Children/BtServices";

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/"
            element={<Login setIsAuthenticated={setIsAuthenticated} />}
          />
          <Route
            element={<ProtectedRoutes isAuthenticated={isAuthenticated} />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/nightlytestview"
              element={<NightlyTest isAuthenticated={isAuthenticated} />}
            />
            <Route
              path="/pipelineview"
              element={<PipelineView isAuthenticated={isAuthenticated} />}
            />
            <Route
              path="/pipelineview/test-setup"
              element={<TestSetUp isAuthenticated={isAuthenticated} />}
            />
            <Route
              path="/pipelineview/software"
              element={<Software isAuthenticated={isAuthenticated} />}
            />
            <Route
              path="/pipelineview/hardware"
              element={<Hardware isAuthenticated={isAuthenticated} />}
            />
            <Route
              path="/pipelineview/datamodel"
              element={<DataModel isAuthenticated={isAuthenticated} />}
            />
            <Route
              path="/pipelineview/orphaned"
              element={<Orphaned isAuthenticated={isAuthenticated} />}
            />
            <Route
              path="/pipelineview/lte-modem"
              element={<LTEModem isAuthenticated={isAuthenticated} />}
            />
            <Route
              path="/pipelineview/software/btservices"
              element={<BtServices isAuthenticated={isAuthenticated} />}
            />
          </Route>
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
