import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {Sidebar} from "../Libraries/PipeLineView/Sidebar";
import {CardComponent} from "../Libraries/PipeLineView/CardComponent";
import Navbar from "../Libraries/Navbar";
import {
  useNavigateToDashboard,
  useNavigateToPipelineViewFromNightlyTest,
} from "../CustomHooks/NavigationHooks";
import PipelineViewTable from "../Libraries/Table/PipeLineViewTable";
import LoadingIndicator from "../Reusable Component/LoadingIndicator";
import withLogout from "../AutoLogOut/withLogout";
import {LOCAL_ROUTES} from "../Utils/enums";

interface PipelineViewProps {
  isAuthenticated: boolean;
}

const PipelineView: React.FC<PipelineViewProps> = ({isAuthenticated}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const {handleNavigateToDashboard} = useNavigateToDashboard(
    isAuthenticated,
    location.pathname,
  );

  const {handleNavigateToPipelineViewFromNightlyTest} =
    useNavigateToPipelineViewFromNightlyTest(
      isAuthenticated,
      location.pathname,
    );

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const token = sessionStorage.getItem("Access");

  const dashboardUrl = `${LOCAL_ROUTES.DASHBOARD_VIEW}#access_token=${token}`;

  const nightlyTestviewUrl = LOCAL_ROUTES.NIGHTLY_TEST_VIEW;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 15000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <Navbar isOpen={isOpen} onToggle={handleToggle} />
      <LoadingIndicator isLoading={isLoading} />
      <div
        className={`flex h-screen w-screen ${isLoading ? "opacity-20" : ""}`}>
        <Sidebar />
        <div className="w-4/5 bg-white p-[1.5rem]">
          <div className="flex items-center text-gray-500 text-sm">
            <a
              href={dashboardUrl}
              className="mr-2 hover:text-gray-700"
              onClick={handleNavigateToDashboard}>
              Home
            </a>
            <span className="mr-2">{">"}</span>
            <a
              href={nightlyTestviewUrl}
              className="mr-2 hover:text-gray-700"
              onClick={handleNavigateToPipelineViewFromNightlyTest}>
              Nightly Test
            </a>
            <span className="mr-2">{">"}</span>
            <a
              href="/"
              className={`mr-2 hover:text-gray-700 ${
                location.pathname === LOCAL_ROUTES.PIPELINE_VIEW
                  ? "font-bold text-black"
                  : "text-gray-700"
              }`}>
              Pipeline View
            </a>
          </div>
          <br />
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-bold font-serif">Summary</h2>
          </div>
          <CardComponent />
          <br />
          <PipelineViewTable />
        </div>
      </div>
    </div>
  );
};

export default withLogout(PipelineView);
