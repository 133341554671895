import React from "react";

interface Props {
  loading: boolean;
  data: any;
  onClick: React.MouseEventHandler<HTMLLIElement>;
  folderTestCount?: any;
}

const ChildTable: React.FC<Props> = ({
  loading,
  data,
  folderTestCount,
  onClick,
}) => {
  if (loading) {
    return (
      <div className="rounded-lg border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1 w-[93.2vw]">
        <div className="px-6 py-24 text-center">
          <div
            className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status">
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="rounded-lg border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1 w-[93.2vw]">
      {data.length > 0 ? (
        <div className="table-container">
          <table className="w-full">
            <thead>
              <tr className="underline underline-offset-8">
                <th className="text-left">Folder Name</th>
                <th className="p-2.5 xl:p-5 text-center">Total Count</th>
                <th className="p-2.5 xl:p-5 text-center">Passed</th>
                <th className="p-2.5 xl:p-5 text-center">Failed</th>
                <th className="p-2.5 xl:p-5 text-center">Skipped</th>
                <th className="p-2.5 xl:p-5 text-center">Missing</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {data.map((item: any, index: number) => (
                <tr className="border-b border-[#eee]" key={index}>
                  &nbsp;
                  <td className="flex items-center">
                    <img src={item.icon} alt={item.label} />
                    &nbsp;
                    <span
                      className="cursor-pointer"
                      onClick={() => onClick(item)}>
                      {item.label}
                    </span>
                  </td>
                  <td className="p-2.5 xl:p-5 text-center">
                    {folderTestCount.find(
                      (folder: {label: any}) => folder.label === item.label,
                    )?.total ?? "N/A"}
                  </td>
                  <td className="p-2.5 xl:p-5 text-center">
                    {folderTestCount.find(
                      (folder: {label: any}) => folder.label === item.label,
                    )?.pass ?? "N/A"}
                  </td>
                  <td className="p-2.5 xl:p-5 text-center">
                    {folderTestCount.find(
                      (folder: {label: any}) => folder.label === item.label,
                    )?.fail ?? "N/A"}
                  </td>
                  <td className="p-2.5 xl:p-5 text-center">
                    {folderTestCount.find(
                      (folder: {label: any}) => folder.label === item.label,
                    )?.skip ?? "N/A"}
                  </td>
                  <td className="p-2.5 xl:p-5 text-center">
                    {folderTestCount.find(
                      (folder: {label: any}) => folder.label === item.label,
                    )?.missing ?? "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="px-6 py-24 text-center" style={{fontWeight: "bold"}}>
          <h1 className="text-bold font-serif">No folders found ☹️ !!</h1>
        </div>
      )}
    </div>
  );
};

export default ChildTable;
