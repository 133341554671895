import React, {useState, useEffect, useCallback} from "react";
import axios, {AxiosError} from "axios";
import DMD from "../../assets/tech.png";
import Orphaned from "../../assets/computer_15689006.png";
import InterfaceStack from "../../assets/stack.png";
import Config from "../../assets/web-management.png";
import {useNavigate} from "react-router-dom";
import ChildTable from "../../Reusable Component/ChildTable";

export const DataModelFolderBar: React.FC = () => {
  const [sidebarItems, setSidebarItems] = useState([]);
  const [apiData, setApiData] = useState({});
  const [folderTestCount, setFolderTestCount] = useState<any>([]);
  const navigate = useNavigate();
  const [tokenFolderDataModel, setTokenFolderDataModel] = useState("");
  const [loading, setLoading] = useState(false);
  const date = sessionStorage.getItem("Pipeline Date");
  const apiUrl = process.env.REACT_APP_API_URL;

  const iconMapping: any = {
    "Data Model Definition": DMD,
    "Interface Stack": InterfaceStack,
    "Data Model Configuration": Config,
    default: Orphaned,
  };

  useEffect(() => {
    const tokenDataModel = sessionStorage.getItem("Access");
    if (tokenDataModel) {
      setTokenFolderDataModel(tokenDataModel);
    }
  });

  const fetchFoldersDataDataModel = useCallback(async () => {
    try {
      setLoading(true);
      const headers = {
        Authorization: tokenFolderDataModel,
        Accept: "*/*",
      };

      try {
        const folderResponse = await axios.get(
          `${apiUrl}/tests/folders/9176?type=NIGHTLY&nightlyDate=${date}`,
          {headers},
        );
        const items = folderResponse.data.childFolders.map((item: any) => ({
          label: item.name.replace(/[0-9]\) | \(x\)|x\)/g, ""),
          icon:
            iconMapping[item.name.replace(/[0-9]\) | \(x\)|x\)/g, "")] ||
            iconMapping.default,
        }));
        setSidebarItems(items);
        setApiData(folderResponse.data);
        if (folderResponse.data.childFolders) {
          const folderTestCount = Object.keys(
            folderResponse.data.childFolders,
          ).map(key => ({
            label: folderResponse.data.childFolders[key].name,
            total: folderResponse.data.childFolders[key].total,
            pass: folderResponse.data.childFolders[key].pass,
            fail: folderResponse.data.childFolders[key].fail,
            skip: folderResponse.data.childFolders[key].skip,
            missing: folderResponse.data.childFolders[key].missing
          }));
          setFolderTestCount(folderTestCount);
        } else {
          setFolderTestCount([]);
        }
      } catch (error: any) {
        if (
          error instanceof AxiosError &&
          error.response &&
          error.response.status === 504
        ) {
          setFolderTestCount([]);
        } else {
          console.error(error);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, [tokenFolderDataModel]);

  useEffect(() => {
    if (tokenFolderDataModel) {
      void fetchFoldersDataDataModel();
    }
  }, [fetchFoldersDataDataModel, tokenFolderDataModel]);

  return (
    <div>
      <ChildTable
        loading={loading}
        data={sidebarItems}
        onClick={function (
          event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        ): void {
          throw new Error("Function not implemented.");
        }}
        folderTestCount={folderTestCount}
      />
    </div>
  );
};
