import React, {useState, useEffect, useCallback, useRef} from "react";
import axios from "axios";
import Software from "../../assets/coding_2010990.png";
import Hardware from "../../assets/cpu_9828862.png";
import DataModel from "../../assets/data-server_4860335.png";
import LTE from "../../assets/icons8-high-speed-lte-generation-network-and-internet-connectivity-logotype-24.png";
import TestSetUp from "../../assets/web-settings-confirmed_74892.png";
import Security from "../../assets/icons8-protect-24.png";
import BTT from "../../assets/verified-user_9957231.png";
import General from "../../assets/document_7988949.png";
import Terminology from "../../assets/science-book_11435156.png";
import Orphaned from "../../assets/computer_15689006.png";
import {useNavigate} from "react-router-dom";

export const Sidebar: React.FC = () => {
  const [sidebarItems, setSidebarItems] = useState([]);
  const navigate = useNavigate();
  const [tokenFolderPipeLineView, setTokenFolderPipeLineView] = useState("");
  const date = sessionStorage.getItem("Pipeline Date");
  const apiUrl = process.env.REACT_APP_API_URL;

  const iconMapping: any = {
    Software: Software,
    Hardware: Hardware,
    "Data Model": DataModel,
    "LTE Modem": LTE,
    "Test Setup": TestSetUp,
    Security: Security,
    "Belfast test team": BTT,
    General: General,
    Terminology: Terminology,
    Orphaned: Orphaned,
    default: Orphaned,
  };

  useEffect(() => {
    const token = sessionStorage.getItem("Access");
    if (token) {
      setTokenFolderPipeLineView(token);
    }
  });

  const fetchFoldersData = useCallback(async () => {
    try {
      const headers = {
        Authorization: tokenFolderPipeLineView,
        Accept: "*/*",
      };
      const response = await axios.get(`${apiUrl}/folders/-1?type=NIGHTLY&nightlyDate=${date}`, {headers});
      const items = response.data.data.map((item: any) => ({
        label: item.name.replace(/[0-9]\) | \(x\)|x\)/g, ""),
        icon:
          iconMapping[item.name.replace(/[0-9]\) | \(x\)|x\)/g, "")] ||
          iconMapping.default,
      }));
      setSidebarItems(items);
    } catch (error) {
      console.error(error);
    }
  }, [tokenFolderPipeLineView]);

  useEffect(() => {
    if (tokenFolderPipeLineView) {
      void fetchFoldersData();
    }
  }, [fetchFoldersData, tokenFolderPipeLineView]);

  return (
    <aside className="bg-gray-400 text-black w-64 h-screen">
      <ul className="mt-4">
        {sidebarItems.map((item: any, index) => (
          <li
            key={index}
            className="px-4 py-2 rounded-md hover:bg-gray-500 cursor-pointer"
            onClick={() => {
              if (item.label === "Test Setup") {
                navigate("/pipelineview/test-setup/");
              } else if (item.label === "Software") {
                navigate("/pipelineview/software/");
              } else if (
                item.label === "Terminology" ||
                item.label === "General" ||
                item.label === "Security" ||
                item.label === "Belfast test team"
              ) {
                alert(
                  `${item.label}: No issues or folders are there at the moment ☹️!!`,
                );
              } else if (item.label === "Hardware") {
                navigate("/pipelineview/hardware/");
              } else if (item.label === "Data Model") {
                navigate("/pipelineview/datamodel/");
              } else if (item.label === " Orphaned") {
                navigate("/pipelineview/orphaned/");
              } else if (item.label === "LTE Modem") {
                navigate("/pipelineview/lte-modem/");
              }
            }}>
            <div className="flex items-center">
              <img src={item.icon} alt={item.label} />
              &nbsp;
              <span>{item.label}</span>
            </div>
          </li>
        ))}
      </ul>
    </aside>
  );
};
