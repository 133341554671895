import {useNavigate} from "react-router-dom";

export const useNavigateToDashboard = (
  isAuthenticated: boolean,
  currentPath: string,
) => {
  const navigate = useNavigate();

  const handleNavigateToDashboard = () => {
    if (isAuthenticated && currentPath === "/pipelineview/") {
      navigate("/dashboard");
    }
  };

  return {handleNavigateToDashboard};
};

export const useNavigateToPipelineViewFromNightlyTest = (
  isAuthenticated: boolean,
  currentPath: string,
) => {
  const navigate = useNavigate();

  const handleNavigateToPipelineViewFromNightlyTest = () => {
    if (isAuthenticated && currentPath === "/pipelineview/") {
      navigate("/nightlytestview/");
    }
  };

  return {handleNavigateToPipelineViewFromNightlyTest};
};

export const useNavigateToSoftwareViewFromNightlyTest = (
  isAuthenticated: boolean,
  currentPath: string,
) => {
  const navigate = useNavigate();

  const handleNavigateToSoftwareViewFromNightlyTest = () => {
    if (isAuthenticated && currentPath === "/pipelineview/software/") {
      navigate("/nightlytestview/");
    }
  };

  return {handleNavigateToSoftwareViewFromNightlyTest};
};

export const useNavigateToBTServicesViewFromNightlyTest = (
  isAuthenticated: boolean,
  currentPath: string,
) => {
  const navigate = useNavigate();

  const handleNavigateToBTServicesViewFromNightlyTest = () => {
    if (isAuthenticated && currentPath === "/pipelineview/software/btservices/") {
      navigate("/nightlytestview/");
    }
  };

  return {handleNavigateToBTServicesViewFromNightlyTest};
};

export const useNavigateToDataModelViewFromNightlyTest = (
  isAuthenticated: boolean,
  currentPath: string,
) => {
  const navigate = useNavigate();

  const handleNavigateToDataModelViewFromNightlyTest = () => {
    if (isAuthenticated && currentPath === "/pipelineview/datamodel/") {
      navigate("/nightlytestview/");
    }
  };

  return {handleNavigateToDataModelViewFromNightlyTest};
};

export const useNavigateToHardwareViewFromNightlyTest = (
  isAuthenticated: boolean,
  currentPath: string,
) => {
  const navigate = useNavigate();

  const handleNavigateToHardwareViewFromNightlyTest = () => {
    if (isAuthenticated && currentPath === "/pipelineview/software/") {
      navigate("/nightlytestview/");
    }
  };

  return {handleNavigateToHardwareViewFromNightlyTest};
};

export const useNavigateToLTEModemViewFromNightlyTest = (
  isAuthenticated: boolean,
  currentPath: string,
) => {
  const navigate = useNavigate();

  const handleNavigateToLTEModemViewFromNightlyTest = () => {
    if (isAuthenticated && currentPath === "/pipelineview/lte-modem/") {
      navigate("/nightlytestview/");
    }
  };

  return {handleNavigateToLTEModemViewFromNightlyTest};
};

export const useNavigateToTestSetUpViewFromNightlyTest = (
  isAuthenticated: boolean,
  currentPath: string,
) => {
  const navigate = useNavigate();

  const handleNavigateToTestSetUpViewFromNightlyTest = () => {
    if (isAuthenticated && currentPath === "/pipelineview/test-setup/") {
      navigate("/nightlytestview/");
    }
  };

  return {handleNavigateToTestSetUpViewFromNightlyTest};
};

export const useNavigateToOrphanedViewFromNightlyTest = (
  isAuthenticated: boolean,
  currentPath: string,
) => {
  const navigate = useNavigate();

  const handleNavigateToOrphanedViewFromNightlyTest = () => {
    if (isAuthenticated && currentPath === "/pipelineview/orphaned/") {
      navigate("/nightlytestview/");
    }
  };

  return {handleNavigateToOrphanedViewFromNightlyTest};
};

export const useNavigateToPipelineView = (
  isAuthenticated: boolean,
  currentPath: string,
) => {
  const navigate = useNavigate();

  const handleNavigateToPipelineView = () => {
    if (isAuthenticated && currentPath === "/pipelineview/test-setup/") {
      navigate("/pipelineview/");
    }
  };

  return {handleNavigateToPipelineView};
};

export const useNavigateToPipelineViewFromSoftware = (
  isAuthenticated: boolean,
  currentPath: string,
) => {
  const navigate = useNavigate();

  const handleNavigateToPipelineViewSoftware = () => {
    if (isAuthenticated && currentPath === "/pipelineview/software/") {
      navigate("/pipelineview/");
    }
  };

  return {handleNavigateToPipelineViewSoftware};
};

export const useNavigateToPipelineViewFromBTService = (
  isAuthenticated: boolean,
  currentPath: string,
) => {
  const navigate = useNavigate();

  const handleNavigateToPipelineViewFromBTService = () => {
    if (isAuthenticated && currentPath === "/pipelineview/software/btservices/") {
      navigate("/pipelineview/");
    }
  };

  return {handleNavigateToPipelineViewFromBTService};
};

export const useNavigateToSoftwareViewFromBTService = (
  isAuthenticated: boolean,
  currentPath: string,
) => {
  const navigate = useNavigate();

  const handleNavigateToSoftwareViewFromBTService = () => {
    if (isAuthenticated && currentPath === "/pipelineview/software/btservices/") {
      navigate("/pipelineview/software/");
    }
  };

  return {handleNavigateToSoftwareViewFromBTService};
};

export const useNavigateToPipelineViewFromHardware = (
  isAuthenticated: boolean,
  currentPath: string,
) => {
  const navigate = useNavigate();

  const handleNavigateToPipelineViewHardware = () => {
    if (isAuthenticated && currentPath === "/pipelineview/hardware/") {
      navigate("/pipelineview/");
    }
  };

  return {handleNavigateToPipelineViewHardware};
};

export const useNavigateToPipelineViewFromDataModel = (
  isAuthenticated: boolean,
  currentPath: string,
) => {
  const navigate = useNavigate();

  const handleNavigateToPipelineViewDataModel = () => {
    if (isAuthenticated && currentPath === "/pipelineview/datamodel/") {
      navigate("/pipelineview/");
    }
  };

  return {handleNavigateToPipelineViewDataModel};
};

export const useNavigateToPipelineViewFromOrphaned = (
  isAuthenticated: boolean,
  currentPath: string,
) => {
  const navigate = useNavigate();

  const handleNavigateToPipelineViewOrphaned = () => {
    if (isAuthenticated && currentPath === "/pipelineview/orphaned/") {
      navigate("/pipelineview/");
    }
  };

  return {handleNavigateToPipelineViewOrphaned};
};

export const useNavigateToPipelineViewFromLTEModem = (
  isAuthenticated: boolean,
  currentPath: string,
) => {
  const navigate = useNavigate();

  const handleNavigateToPipelineViewLTEModem = () => {
    if (isAuthenticated && currentPath === "/pipelineview/lte-modem/") {
      navigate("/pipelineview/");
    }
  };

  return {handleNavigateToPipelineViewLTEModem};
};
