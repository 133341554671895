import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {CardComponent} from "../../Libraries/DataModel/CardComponent";
import Navbar from "../../Libraries/Navbar";
import {
  useNavigateToDashboard,
  useNavigateToDataModelViewFromNightlyTest,
  useNavigateToPipelineViewFromDataModel,
} from "../../CustomHooks/NavigationHooks";
import {DataModelFolderBar} from "../../Libraries/DataModel/ChildFolders";
import DataModelTable from "../../Libraries/Table/DataModelTable";
import LoadingIndicator from "../../Reusable Component/LoadingIndicator";
import withLogout from "../../AutoLogOut/withLogout";
import {LOCAL_ROUTES} from "../../Utils/enums";

interface Props {
  isAuthenticated: boolean;
}

const DataModel: React.FC<Props> = ({isAuthenticated}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const {handleNavigateToDashboard} = useNavigateToDashboard(
    isAuthenticated,
    location.pathname,
  );

  const {handleNavigateToPipelineViewDataModel} =
    useNavigateToPipelineViewFromDataModel(isAuthenticated, location.pathname);

  const {handleNavigateToDataModelViewFromNightlyTest} =
    useNavigateToDataModelViewFromNightlyTest(
      isAuthenticated,
      location.pathname,
    );

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const token = sessionStorage.getItem("Access");

  const dashboardUrl = `${LOCAL_ROUTES.DASHBOARD_VIEW}#access_token=${token}`;

  const pipelineviewUrl = LOCAL_ROUTES.PIPELINE_VIEW;

  const nightlyTestviewUrl = LOCAL_ROUTES.NIGHTLY_TEST_VIEW;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 15000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <Navbar isOpen={isOpen} onToggle={handleToggle} />
      <LoadingIndicator isLoading={isLoading} />
      <div
        className={`flex h-screen w-[120vw] p-8 ${isLoading ? "opacity-20 cursor-not-allowed" : ""}`}>
        <div className="w-4/5 bg-white -p-[0.5rem]">
          <div className="flex items-center text-gray-500 text-sm">
            <a
              href={dashboardUrl}
              className="mr-2 hover:text-gray-700"
              onClick={handleNavigateToDashboard}>
              Home
            </a>
            <span className="mr-2">{">"}</span>
            <a
              href={nightlyTestviewUrl}
              className="mr-2 hover:text-gray-700"
              onClick={handleNavigateToDataModelViewFromNightlyTest}>
              Nightly Test
            </a>
            <span className="mr-2">{">"}</span>
            <a
              href={pipelineviewUrl}
              className="mr-2 hover:text-gray-700"
              onClick={handleNavigateToPipelineViewDataModel}>
              Pipeline View
            </a>
            <span className="mr-2">{">"}</span>
            <a
              href="#"
              className={`mr-2 hover:text-gray-700 ${
                location.pathname === LOCAL_ROUTES.DATA_MODEL_VIEW
                  ? "font-bold text-black"
                  : "text-gray-700"
              }`}>
              Data Model
            </a>
          </div>
          <br />
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-bold font-serif">Summary</h2>
          </div>
          <CardComponent />
          &nbsp;
          <DataModelFolderBar />
          <br />
          <DataModelTable />
        </div>
      </div>
    </div>
  );
};

export default withLogout(DataModel);
