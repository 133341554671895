import React from "react";
import {Line} from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
} from "chart.js";

interface IGraph {
  cardData: any;
}

ChartJS.register(
  CategoryScale,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
);

const Graph: React.FC<IGraph> = ({cardData}) => {
  const chartData = {
    labels: cardData.map((card: {date: any}) => card.date),
    datasets: [
      {
        label: "Pass",
        data: cardData.map((card: {pass: any}) => card.pass),
        borderColor: "green",
      },
      {
        label: "Fail",
        data: cardData.map((card: {fail: any}) => card.fail),
        borderColor: "red",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Nightly Test Results",
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const label = context.dataset.label;
            const value = context.parsed.y;
            const date = context.label;
            if (label === "Pass") {
              return `Pass: ${value} on ${date}`;
            } else {
              return `Fail: ${value} on ${date}`;
            }
          },
        },
        displayColors: true,
        backgroundColor: function (context: any) {
          return context.dataset.borderColor;
        },
        font: {
          size: 14,
        },
        color: {
          opacity: 0.8,
        },
        padding: 10,
      },
    },
  };
  return (
    <div className="mt-4">
      <br />
      <h2 className="text-xl font-bold mb-4 font-serif">
        Graphical Representation:{" "}
      </h2>
      <div className="relative">
        <div className="w-full h-96 px-96">
          <Line options={chartOptions} data={chartData} />
        </div>
      </div>
    </div>
  );
};

export default Graph;
