import React, {useCallback, useEffect, useState} from "react";
import CardDataStats from "../CardData/CardDataStats";
import ArrowUp from "../../assets/icons8-up-arrow-26.png";
import ArrowDown from "../../assets/icons8-down-arrow-26.png";
import TestIcon from "../../assets/icons8-test-100.png";
import Passed from "../../assets/icons8-test-passed-100.png";
import Failed from "../../assets/icons8-scorecard-100.png";
import Skipped from "../../assets/icons8-skip-100.png";
import axios from "axios";

export const CardComponent: React.FC = () => {
  const [apiData, setApiData] = useState({
    total: 0,
    pass: 0,
    fail: 0,
    skip: 0,
    missing: 0,
  });

  const [tokenFolderDataModel, setTokenFolderDataModel] = useState("");
  const date = sessionStorage.getItem("Pipeline Date");
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const tokenDataModel = sessionStorage.getItem("Access");
    if (tokenDataModel) {
      setTokenFolderDataModel(tokenDataModel);
    }
  });

  const fetchFoldersDataDataModel = useCallback(async () => {
    try {
      const headers = {
        Authorization: tokenFolderDataModel,
        Accept: "*/*",
      };
      const response = await axios.get(
        `${apiUrl}/tests/folders/9176?type=NIGHTLY&nightlyDate=${date}`,
        {headers},
      );
      setApiData(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [tokenFolderDataModel]);

  useEffect(() => {
    if (tokenFolderDataModel) {
      void fetchFoldersDataDataModel();
    }
  }, [fetchFoldersDataDataModel, tokenFolderDataModel]);

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-5 2xl:gap-7.5">
      <CardDataStats
        title="TCS"
        total={apiData.total}
        rate={100}
        levelUp
        icon={ArrowUp}
        cardIcon={TestIcon}
      />
      <CardDataStats
        title="Passed"
        total={apiData.pass}
        rate={Number(((apiData.pass / apiData.total) * 100).toFixed(2))}
        levelUp
        icon={ArrowUp}
        cardIcon={Passed}
      />
      <CardDataStats
        title="Failed"
        total={apiData.fail}
        rate={Number(((apiData.fail / apiData.total) * 100).toFixed(2))}
        levelUp
        icon={ArrowUp}
        cardIcon={Failed}
      />
      <CardDataStats
        title="Skipped"
        total={apiData.skip || 0}
        rate={Number(((apiData.skip / apiData.total) * 100).toFixed(2))}
        levelDown
        icon={ArrowDown}
        cardIcon={Skipped}
      />
      <CardDataStats
        title="Missing"
        total={apiData.missing || 0}
        rate={Number(((apiData.missing / apiData.total) * 100).toFixed(2))}
        levelDown
        icon={ArrowDown}
        cardIcon={Skipped}
      />
    </div>
  );
};
